<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>

		<div v-loading="loading">
			<!-- รายละเอียดการคืนเงินstart -->
			<div>
				<el-card class="box-card order-list">
					<div slot="header" class="clearfix">
						<el-breadcrumb separator="/">
							<el-breadcrumb-item :to="{ path: '/member/activist' }" style="     font-family: 'Prompt';
    font-weight: 500; font-size: 15px; " >การคืนเงิน</el-breadcrumb-item>
							<el-breadcrumb-item>รายละเอียดการคืนเงิน</el-breadcrumb-item>
						</el-breadcrumb>
					</div>

					<div>
						<div class="block-text color-red" v-if="detail.refund_status==-1">{{ detail.refund_status_name }}</div>
						<div class="block-text color-green" v-if="detail.refund_status==3">การคุ้มครองสิทธิ์ที่ประสบความสําเร็จ</div>
						<div class="fail-text" v-if="detail.refund_status==-1">
							<div class="fail-title">เหตุผลในการปฏิเสธ:</div>
							<div class="fail-detail">{{ detail.refund_refuse_reason }}</div>
						</div>
						<div class="block-text" v-if="detail.refund_status==1 || detail.refund_status==2 || detail.refund_status==4 || detail.refund_status==5 || detail.refund_status==6 || detail.refund_status==-2">{{ detail.refund_status_name }}</div>
						<div class="status-wrap">
							<div class="refund-explain" v-if="detail.refund_status == 1">
								<div>หากผู้ค้าปฏิเสธ，คุณสามารถเริ่มต้นแอปพลิเคชันของคุณอีกครั้งได้</div>
								<div>หากผู้ขายตกลง，จะได้รับเงินคืนให้คุณตามคําขอ</div>
								<div>หากผู้ขายเกินกําหนด，แพลตฟอร์มจะสมัครและคืนเงินให้คุณโดยอัตโนมัติ</div>
							</div>
							<div class="refund-explain" v-if="detail.refund_status == 5">
								<div>หากผู้ขายยืนยันการรับคุณจะได้รับเงินคืน</div>
								<div>หากร้านค้าปฏิเสธที่จะรับสินค้า，การคืนเงินจะถูกปิด，คุณสามารถเริ่มการคืนเงินอีกครั้งได้</div>
							</div>
						</div>
					</div>
				</el-card>

				<!--บันทึกการเจรจาต่อรอง-->
				<div class="item-block">
					<div class="action-box">
						<span class="media-left">บันทึกการเจรจาต่อรอง</span>
						<div class="media-right">
							<div class="el-button--text" @click="actionOpen ? (actionOpen = false) : (actionOpen = true)">
								บันทึกการเจรจาต่อรอง
								<i :class="actionOpen ? 'rotate' : ''" class="el-icon-arrow-down"></i>
							</div>
						</div>
						<div class="clear"></div>
					</div>
					<div v-if="actionOpen">
						<el-timeline>
							<el-timeline-item :class="logItem.action_way == 1 ? 'buyer' : 'seller'" v-for="(logItem, logIndex) in detail.refund_log_list"
							 :key="logIndex" :timestamp="$util.timeStampTurnTime(logItem.action_time)" placement="top">
								<div>
									<h4>{{ logItem.action_way == 1 ? 'ซื้อ' : 'ผู้จําหน่าย' }}</h4>
									<p>{{ logItem.action }}</p>
								</div>
							</el-timeline-item>
						</el-timeline>
					</div>
				</div>

				<!-- ที่อยู่ผู้ส่ง -->
				<div class="item-block" v-if="detail.refund_status == 4">
					<div class="block-text">
						<span>ผู้รับสินค้า：{{ detail.shop_contacts }}</span>
						<span>เบอร์ติดต่อ：{{ detail.shop_mobile }}</span>
					</div>
					<div class="block-text">
						<span>ที่อยู่ผู้ส่ง：{{ detail.shop_address }}</span>
					</div>
				</div>

				<!--รายละเอียดการคืนเงิน-->
				<div class="item-block">
					<div class="goods-list">
						<table>
							<tr>
								<td width="62.5%">สินค้า</td>
								<td width="12.5%">ปริมาณ</td>
								<td width="12.5%">จํานวนเงินที่คืน</td>
							</tr>
						</table>
					</div>
				</div>

				<div class="item-block">
					<div class="goods-list">
						<table>
							<tr>
								<td width="62.5%">
									<div class="goods-info">
										<div class="goods-info-left">
											<router-link :to="{ path: '/sku-' + detail.sku_id }" target="_blank">
												<img class="goods-img" :src="$img(detail.sku_image)" @error="detail.sku_image = defaultGoodsImage" />
											</router-link>
										</div>
										<div class="goods-info-right">
											<router-link :to="{ path: '/sku-' + detail.sku_id }" target="_blank">
												<div class="goods-name">{{ detail.sku_name }}</div>
											</router-link>
										</div>
									</div>
								</td>
								<td width="12.5%" class="goods-num">{{ detail.num }}</td>
								<td width="12.5%" class="goods-money">฿{{ detail.refund_apply_money }}</td>
							</tr>
						</table>
					</div>
				</div>

				<div class="item-block">
					<div class="order-statistics">
						<table>
							<tr>
								<td align="right">วิธีการคืนเงิน：</td>
								<td align="left">{{ detail.refund_type == 1 ? 'การคืนเงินเท่านั้น' : 'การคืนเงินคืนสินค้า' }}</td>
							</tr>
							<tr>
								<td align="right">เหตุผลในการคืนเงิน：</td>
								<td align="left">{{ detail.refund_reason }}</td>
							</tr>
							<tr>
								<td align="right">จํานวนเงินที่คืน：</td>
								<td align="left">฿{{ detail.refund_apply_money }}</td>
							</tr>
							<tr>
								<td align="right">หมายเลขการคืนเงิน：</td>
								<td align="left">{{ detail.refund_no }}</td>
							</tr>
							<tr>
								<td align="right">เวลาสมัคร：</td>
								<td align="left">{{ $util.timeStampTurnTime(detail.refund_action_time) }}</td>
							</tr>
							<tr v-if="detail.refund_time">
								<td align="right">เวลาคืนเงิน：</td>
								<td align="left">{{ $util.timeStampTurnTime(detail.refund_time) }}</td>
							</tr>
						</table>
					</div>
					<div class="clear"></div>
				</div>

				<div class="item-block" v-if="detail.refund_action.length">
					<div class="order-submit" v-for="(actionItem, actionIndex) in detail.refund_action" :key="actionIndex">
						<el-button type="primary" class="el-button--primary" @click="refundAction(actionItem.event)">{{ actionItem.title }}</el-button>
					</div>

					<div class="order-submit" v-if="detail.complain_action">
						<el-button type="primary" class="el-button--primary" @click="refundAction('complain')">การปกป้องสิทธิ์ของแพลตฟอร์ม</el-button>
					</div>

					<div class="clear"></div>
				</div>
			</div>
			<!-- รายละเอียดการคืนเงินend -->

			<!-- การป้อนข้อมูลโลจิสติกส์จะปรากฏขึ้น -->
			<el-dialog title="ป้อนโลจิสติกส์การจัดส่ง" :visible.sync="refundDeliveryDialog" width="50%">
				<el-form ref="form" :model="formData" label-width="80px">
					<el-form-item label="บริษัทโลจิสติกส์">
						<el-input v-model="formData.refund_delivery_name" placeholder="โปรดเข้าสู่บริษัทโลจิสติกส์"></el-input>
					</el-form-item>

					<el-form-item label="จํานวนสลิปโลจิสติกส์">
						<el-input v-model="formData.refund_delivery_no" placeholder="โปรดป้อนหมายเลขสลิปโลจิสติกส์"></el-input>
					</el-form-item>

					<el-form-item label="คําแนะนําด้านโลจิสติกส์">
						<el-input v-model="formData.refund_delivery_remark" placeholder="เสริม"></el-input>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button @click="refundDeliveryDialog = false">ยกเลิก</el-button>
					<el-button type="primary" @click="refurnGoods('form')">เพิ่มข้อมูล</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import {
		refundData,
		refund,
		detail,
		delivery,
		cancleRefund
	} from '@/api/order/refund';
	import {
		mapGetters
	} from 'vuex';
	export default {
		name: 'refund_detail',
		components: {},
		data: () => {
			return {
				orderGoodsId: '',
				isSub: false,
				detail: {
					refund_action: []
				},
				formData: {
					refund_delivery_name: '',
					refund_delivery_no: '',
					refund_delivery_remark: ''
				},
				actionOpen: false, //บันทึกการเจรจาต่อรอง
				refundDeliveryDialog: false, //ที่อยู่จัดส่งจะปรากฏขึ้น
				loading: true,
				yes: true
			};
		},
		created() {
			if (this.$route.query.order_goods_id) this.orderGoodsId = this.$route.query.order_goods_id;
			this.getRefundDetail();
			if (this.$route.query.action && this.$route.query.action == 'returngoods') {
				this.refundDeliveryDialog = true;
			}
		},
		computed: {
			...mapGetters(['defaultGoodsImage'])
		},
		mounted() {
			let self = this;
			setTimeout(function() {
				self.yes = false
			}, 300)
		},
		methods: {
			//รายละเอียดการคืนเงินมีความเกี่ยวข้อง
			getRefundDetail() {
				this.loading = true;
				detail({
						order_goods_id: this.orderGoodsId
					})
					.then(res => {
						const {
							code,
							message,
							data
						} = res;
						if (code >= 0) {
							this.detail = data;
						} else {
							this.$notify({
								message: 'ไม่ได้รับข้อมูลการคืนเงินสําหรับสินค้าเฉพาะรายการ！',
								title: 'คำเตือน',type: 'warning',
								duration: 2000,
								onClose: () => {
									this.$router.push({
										path: '/member/activist'
									});
								}
							});
						}
						this.loading = false;
					})
					.catch(err => {
						this.loading = false;
						this.$message.error({
							message: err.message,
							duration: 2000,
							onClose: () => {
								this.$router.push({
									path: '/member/activist'
								});
							}
						});
					});
			},
			refundAction(event) {
				switch (event) {
					case 'orderRefundCancel':
						this.cancleRefund(this.detail.order_goods_id);
						break;
					case 'orderRefundDelivery':
						this.refundDeliveryDialog = true;
						break;
					case 'orderRefundAsk':
						this.$router.push({
							path: '/member/refund?order_goods_id=' + this.detail.order_goods_id
						});
						break;
					case 'complain':
						this.$router.push({
							path: '/member/complain?order_goods_id=' + this.detail.order_goods_id
						});
						break;
				}
			},
			refurnGoods() {
				if (this.formData.refund_delivery_name == '') {
					this.$notify({
						message: 'โปรดเข้าสู่บริษัทโลจิสติกส์',
						title: 'คำเตือน',type: 'warning'
					});
					return false;
				}
				if (this.formData.refund_delivery_no == '') {
					this.$notify({
						message: 'โปรดป้อนหมายเลขสลิปโลจิสติกส์',
						title: 'คำเตือน',type: 'warning'
					});
					return false;
				}
				this.formData.order_goods_id = this.orderGoodsId;
				if (this.isSub) return;
				this.isSub = true;

				delivery(this.formData)
					.then(res => {
						const {
							code,
							message,
							data
						} = res;
						if (code >= 0) {
							this.getRefundDetail();
							this.refundDeliveryDialog = false;
						} else {
							this.$notify({
								message: 'ไม่ได้รับข้อมูลการคืนเงินสําหรับสินค้าเฉพาะรายการ！',
								title: 'คำเตือน',type: 'warning',
								duration: 2000,
								onClose: () => {
									this.$router.push({
										path: '/member/activist'
									});
								}
							});
						}
					})
					.catch(err => {
						this.$message.error({
							message: err.message,
							duration: 2000,
							onClose: () => {
								this.$router.push({
									path: '/member/activist'
								});
							}
						});
					});
			},
			cancleRefund(order_goods_id) {
				this.$confirm('เมื่อถอนใบสมัครจะถูกปิด,หากยังมีปัญหาอยู่ในอนาคตคุณสามารถสมัครใหม่ได้', 'พร้อมท์', {
						confirmButtonText: 'ยืนยันการเพิกถอน',
						cancelButtonText: 'ไม่มีการเพิกถอน',
						title: 'คำเตือน',type: 'warning'
					})
					.then(() => {
						if (this.isSub) return;
						this.isSub = true;

						cancleRefund({
								order_goods_id: order_goods_id
							})
							.then(res => {
								const {
									code,
									message,
									data
								} = res;
								if (code >= 0) {
									this.$notify({
										message: 'การเพิกถอนสําเร็จ！',
										title: 'เรียบร้อยแล้ว',type: 'success',
										duration: 2000,
										onClose: () => {
											this.$router.push({
												path: '/member/activist'
											});
										}
									});
								} else {
									this.$notify({
										message: message,
										title: 'คำเตือน',type: 'warning'
									});
								}
							})
							.catch(err => {
								this.$message.error({
									message: err.message,
									duration: 2000,
									onClose: () => {
										this.$router.push({
											path: '/member/activist'
										});
									}
								});
							});
					})
					.catch(() => {});
			}
		}
	};
</script>
<style lang="scss" scoped>
	.box {
		width: 100%;
		position: relative;
	}

	.null-page {
		width: 100%;
		height: 730px;
		background-color: #FFFFFF;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}

	.el-card.is-always-shadow,
	.el-card.is-hover-shadow:focus,
	.el-card.is-hover-shadow:hover {
		box-shadow: unset;
	}

	.el-card {
		border: 0;
	}

	.clear {
		clear: both;
	}

	.item-block {
		padding: 0 20px 1px;
		margin: 10px 0;
		border-radius: 0;
		border: none;
		background: #ffffff;

		.block-text {
			border-color: #eeeeee;
			color: $ns-text-color-black;
			padding: 7px 0;
			border-bottom: 1px;

			span {
				margin-right: 60px;
			}
		}


	}

	.fail-text {
		display: flex;
		align-items: flex-start;
		color: #F94460;
		padding: 7px 0;
		border-bottom: 1px;

		.fail-title {
			width: 7%;
		}

		.fail-detail {
			width: 93%;
		}
	}

	.color-green {
		color: #34C25D;
	}

	.color-red {
		color: #F94460;
	}

	.order-submit {
		float: right;
		padding: 10px;
	}

	.goods-list {
		padding: 15px 0;

		table {
			width: 100%;
		}

		.goods-info-left {
			width: 60px;
			height: 60px;
			float: left;

			.goods-img {
				width: 60px;
				height: 60px;
			}
		}

		.goods-info-right {
			float: left;
			height: 60px;
			margin-left: 10px;
			color: $base-color;
			width: 80%;

			.goods-name {
				line-height: 20px;
				padding-top: 10px;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
			}

			.goods-spec {
				color: #999;
			}
		}
	}

	.pay-type-list {
		padding: 20px 0;
	}

	.pay-type-item {
		display: inline-block;
		border: 2px solid #eeeeee;
		padding: 5px 20px;
		margin-right: 20px;
		cursor: pointer;
	}

	.pay-type-item.active {
		border-color: $base-color;
	}

	.status-wrap {
		color: #999;
	}

	.media-left {
		float: left;
	}

	.media-right {
		float: right;
		cursor: pointer;

		i.rotate {
			transform: rotate(180deg);
			transition: 0.3s;
		}
	}

	.action-box {
		padding: 10px 0;
	}

	.action-way {
		float: left;
		color: #999;
	}

	.head .time {
		float: right;
		color: #999;
	}

	.record-item {
		margin-bottom: 10px;
	}

	.order-statistics {
		float: left;
		padding: 10px;
		// color: #999;
	}
</style>
