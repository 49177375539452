import http from "../../utils/http"

/**
 * ข้อมูลการคืนเงิน
 * @param {object} params
 */
export function refundData(params) {
    return http({
        url: "/api/orderrefund/refundData",
        data: params,
        forceLogin: true
    })
}
/**
 * ข้อมูลการคืนเงินจํานวนมาก
 * @param {object} params
 */
 export function refundDataBatch(params) {
    return http({
        url: "/api/orderrefund/refundDataBatch",
        data: params,
        forceLogin: true
    })
}
/**
 * คืน
 * @param {object} params
 */
export function refund(params) {
    return http({
        url: "/api/orderrefund/refund",
        data: params,
        forceLogin: true
    })
}
/**
 * รายละเอียดการคืนเงิน
 * @param {object} params
 */
export function detail(params) {
    return http({
        url: "/api/orderrefund/detail",
        data: params,
        forceLogin: true
    })
}

/**
 * โลจิสติกส่งคืน
 * @param {object} params
 */
export function delivery(params) {
    return http({
        url: "/api/orderrefund/delivery",
        data: params,
        forceLogin: true
    })
}

/**
 * การเพิกถอนการคุ้มครองสิทธิ
 * @param {object} params
 */
export function cancleRefund(params) {
    return http({
        url: "/api/orderrefund/cancel",
        data: params,
        forceLogin: true
    })
}

/**
 * ข้อมูลการปกป้องสิทธิ์ของแพลตฟอร์ม
 * @param {object} params
 */
export function complainData(params) {
    return http({
        url: "/api/ordercomplain/detail",
        data: params,
        forceLogin: true
    })
}

/**
 * แอปพลิเคชันคุ้มครองสิทธิ์ของแพลตฟอร์ม
 * @param {object} params
 */
export function complain(params) {
    return http({
        url: "/api/ordercomplain/complain",
        data: params,
        forceLogin: true
    })
}

/**
 * การเพิกถอนการคุ้มครองสิทธิ์ของแพลตฟอร์ม
 * @param {object} params
 */
export function complainCancel(params) {
    return http({
        url: "/api/ordercomplain/cancel",
        data: params,
        forceLogin: true
    })
}

/**
 * รายการการคืนเงิน
 * @param {object} params
 */
export function refundList(params) {
    return http({
        url: "/api/orderrefund/lists",
        data: params,
        forceLogin: true
    })
}
